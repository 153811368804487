.ant-radio-wrapper {
  display: flex;
  cursor: pointer;
  font-size: 22px;
  margin-bottom: 20px;
  margin-left: 5px;
  span {
    font-family: 'KumbhSans-Bold';
  }
}
.ant-form-item input[type='radio'] + span {
  display: inline-block;
  border-radius: 7px;
  width: 20px;
  height: 20px;
  margin: 2px;
  vertical-align: middle;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 25px;
  background: #a7a9ac;
  opacity: 0.35;
}

.ant-form-item input[type='radio']:checked + span {
  background: #5c9424;
  opacity: 1;
  &:after {
    display: none;
  }
}

.ant-form-item input[type='radio'] {
  height: 20px;
  width: 20px;
  border-radius: 7px;
  display: none;
}


input {
  background: white !important;
  .ant-radio {
    // border: 1px solid #a7a9ac;
    height: 26px;
    width: 26px;
    border-radius: 5px;
  }
}

.ant-radio-checked::after {
  border: 1px solid #d9d9d9;
  height: 0px;
  width: 0px;
}

.ant-form-item input[type='radio']:after {
  display: none;
}

.ant-select-item-option-content,
.ant-select-selection-item-content {
  color: #939597;
  font-family: 'KumbhSans-Regular';
}

.ant-select-selection-placeholder {
  height: 30px;
  display: flex;
  align-items: center;
}

.ant-select-item-option.ant-select-item-option-selected {
  background-color: rgba(217, 30, 73, 0.1);
  svg {
    fill: rgba(217, 30, 73, 1);
  }
}

.ant-select-selector,
.ant-input, .ant-picker {
  opacity: 0.25!important;
  // border: 0 !important;
  border-radius: 4.6px !important;
  background-color: #fff !important;
  border-width: 0.6px;
  opacity: 1 !important;
  color: #939597;
  border: solid 0.9px #939597;
  input {
    height: 30px;
  }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ant-form-item {
    margin-bottom: 60px !important; /* IE10 */
  }
}

.ant-form-item-explain-error {
  margin: 2px 0;
}
// accordion

.ant-collapse-header {
  color: #939597 !important;
  font-family: 'KumbhSans-Bold';
  font-size: 26px;
  background: #f6f6f7 !important;
}

.ant-collapse-content-box {
  background: #f6f6f7 !important;
  padding: 0 !important;
}
.ant-collapse-content {
  background: #f6f6f7 !important;
}
