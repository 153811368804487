@import './reset.scss';
@import './normalise.scss';
@import '~antd/dist/antd.css';
@import '../assets/fonts/Delm-Black.ttf';
@import '../assets/fonts/Delm.ttf';
@import './antd.scss';

@font-face {
  font-family: 'KumbhSans-Regular';
  src: local('KumbhSans-Regular'), url(../assets/fonts/KumbhSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'KumbhSans-Bold';
  src: local('KumbhSans-Bold'), url(../assets/fonts/KumbhSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'KumbhSans-Light';
  src: local('KumbhSans-Light'), url(../assets/fonts/KumbhSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(../assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Black';
  src: local('Inter-Black'), url(../assets/fonts/Inter-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'), url(../assets/fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'PlayfairDisplay-Regular';
  src: local('PlayfairDisplay-Regular'),
    url(../assets/fonts/PlayfairDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PlayfairDisplay-Bold';
  src: local('PlayfairDisplay-Bold'),
    url(../assets/fonts/PlayfairDisplay-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'PlayfairDisplay-Italic';
  src: local('PlayfairDisplay-Italic'),
    url(../assets/fonts/PlayfairDisplay-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'SpaceMono-Regular';
  src: local('SpaceMono-Regular'), url(../assets/fonts/SpaceMono-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SpaceMono-Bold';
  src: local('SpaceMono-Bold'), url(../assets/fonts/SpaceMono-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SpaceMono-Italic';
  src: local('SpaceMono-Italic'), url(../assets/fonts/SpaceMono-Italic.ttf) format('truetype');
}
